export default {
  methods: {
    startObserverLoading(ref, loadFunction) {
      if (process.client && ref) {
        // Set a dynamic rootMargin based on whether the device is mobile
        const rootMargin = this.$device.isMobile ? '50px' : '100px'

        // Create an IntersectionObserver with corrected options
        const observer = new IntersectionObserver(
          (entries, observer) => {
            entries.forEach(entry => {
              if (entry.intersectionRatio > 0) {
                if (entry.isIntersecting) {
                  // Use isIntersecting for a more direct approach

                  loadFunction()

                  // If you only want to observe the element once
                  observer.unobserve(entry.target)
                }
                // Optionally unobserve the element after the first load if it's a one-time load
                // observer.unobserve(entry.target);
              }
            })
          },
          {
            rootMargin,
            root: null, // Defaults to the viewport, which is usually what you want
          },
        )

        // Start observing the ref element
        observer.observe(ref)
      }
    },
  },
}
