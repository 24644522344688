import { render, staticRenderFns } from "./MPProductPagination.vue?vue&type=template&id=2ae19dda&scoped=true"
import script from "./MPProductPagination.vue?vue&type=script&lang=js"
export * from "./MPProductPagination.vue?vue&type=script&lang=js"
import style0 from "./MPProductPagination.vue?vue&type=style&index=0&id=2ae19dda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_webpack@4.47.0__css-loader@5.2.7_webpack@4.47.0__handle_cutex6v7bvjfvrrsihidbsse5i/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae19dda",
  null
  
)

export default component.exports