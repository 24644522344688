
import { mapGetters, mapMutations } from 'vuex'
import { useMarketStore } from '~/stores/market'
export default {
  name: 'MPProductsShowMore',
  props: {
    viewMode: {
      type: String,
      default: '',
    },
  },
  setup() {
    const marketStore = useMarketStore()
    return {
      marketStore,
    }
  },
  computed: {
    showModeButton() {
      if (
        this.marketStore.pagination?.total_pages > 1 &&
        this.marketStore.pagination?.total_pages >
          this.marketStore.pagination?.page + this.marketStore.moreProductPage
      ) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    $route() {
      this.marketStore.moreProductPage = 0
    },
  },
  destroyed() {
    this.marketStore.moreProductPage = 0
  },
  methods: {
    showMore() {
      this.marketStore.moreProductOptions.options.page =
        this.marketStore.pagination.page + this.marketStore.moreProductPage + 1
      this.marketStore.moreProductPage = this.marketStore.moreProductPage + 1
      this.marketStore.getMoreProduct(this.marketStore.moreProductOptions)
      const url = new URL(window.location)
      const defaultQueries = {
        ...this.$route.query,
        page: this.marketStore.moreProductOptions.options.page,
      }
      const query = new URLSearchParams()
      Object.entries(defaultQueries).map(([key, value]) => {
        query.append(key, value)
      })
      url.href = `${url.origin}${url.pathname}?${query.toString()}`
      window.history.pushState({}, '', url)
    },
  },
}
